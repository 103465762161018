import { observable, action } from 'mobx';
import { IConfig } from './IConfig';
import { IConfigMapping } from '../config-mappings/IConfigMapping';
import { IResolveContext } from '../resolvers/IResolveContext';
import { IConfigResolver } from '../resolvers/IConfigResolver';
import { DeepSearchResolver } from '../resolvers/DeepSearchResolver';
import { FieldAccessResolver } from '../resolvers/FieldAccessResolver';

export class Config {
    @observable public config: IConfig;
    @observable public mapping: IConfigMapping = {};

    public static fromJSON(data: IConfig) {
        const config = new Config();
        config.setConfig(data);

        return config;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private isConfigDeclared = (value: any) => {
        return typeof value === 'boolean' || typeof value === 'number' || !!value;
    };

    public getConfigByName = (configName: string) => {
        const context: IResolveContext = {
            config: this.config,
            mapping: this.mapping
        };
        let resolver: IConfigResolver = DeepSearchResolver;

        const mappedResolver = this.mapping[configName];

        if (
            typeof mappedResolver === 'string' ||
            mappedResolver instanceof String ||
            configName.includes('.')
        ) {
            resolver = FieldAccessResolver;
        } else if (typeof mappedResolver === 'function') {
            resolver = mappedResolver;
        }

        if (!resolver) {
            resolver = DeepSearchResolver;
        }

        const value = resolver(configName, context);

        return this.isConfigDeclared(value) ? value : '';
    };

    @action
    public setMapping(mapping: IConfigMapping) {
        this.mapping = mapping;
    }

    @action
    private setConfig = (config: IConfig) => {
        this.config = config;
    };
}
