import { IConfigResolver } from './IConfigResolver';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function canBeAccessed(result: any) {
    return result !== null && result !== undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function safeEvaluate(obj: any, expression: string) {
    const fields = (expression || '')
        .trim()
        .split('.')
        .map(field => field.trim());

    const fieldCount = fields.length;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let result: any;

    let currentObj = obj;
    let fieldIndex;
    for (fieldIndex = 0; fieldIndex < fieldCount; fieldIndex += 1) {
        if (canBeAccessed(currentObj)) {
            const fieldName = fields[fieldIndex];
            currentObj = currentObj[fieldName];
        } else {
            break;
        }
    }

    if (fieldIndex === fieldCount) {
        result = currentObj;
    }

    return result;
}

export const FieldAccessResolver: IConfigResolver = (name, ctx) => {
    const mappedExpression = ctx.mapping[name];
    if (!mappedExpression && !name.includes('.')) {
        return undefined;
    }

    return mappedExpression
        ? safeEvaluate(ctx.config, mappedExpression.toString())
        : safeEvaluate(ctx.config, name);
};
