import { ConfigMangerApiSdk } from '../../api/ConfigMangerApiSdk';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Config } from '../domain/entities/Config';
import { IConfigMapping } from '../domain/config-mappings/IConfigMapping';
import { BrowserStorage, root } from '@estee/elc-universal-utils';
import { ELCLogger, TriggerType } from '@estee/elc-logging';

const { name, version } = __serviceInfo__;
export interface IConfig {
    [key: string]: string;
}
const configMap: IConfig = {
    isSaveForLaterFeatureEnabled: 'saveForLaterFeatureEnabled',
    itemMaxQuantity: 'item_max_quantity',
    orderMaxValue: 'order_max_value',
    orderSkuMaxLimit: 'order_sku_max_limit',
    currency: 'currency',
    noOrdersRedirectUrl: 'no_orders_redirect',
    features: 'features'
};

export class ConfigRepository {
    private configMangerApiSdk: ConfigMangerApiSdk;
    private ecommConfig: Config;
    public buidConfig: Config;
    private logger: ELCLogger;

    private configMapping: IConfigMapping;

    constructor() {
        this.configMangerApiSdk = diContainer.get(serviceNames.configMangerApiSdk);
        this.ecommConfig = Config.fromJSON({});
        this.buidConfig = Config.fromJSON({});
        this.setMapping({});
        this.logger = new ELCLogger({
            serviceName: name,
            environment: root.env,
            buid: root.buid,
            serviceVersion: version
        });
    }

    private get isCacheable() {
        return (
            window &&
            'sessionStorage' in window &&
            Object.getPrototypeOf(sessionStorage) === Storage.prototype
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private storeInCache(cacheKey: string, cacheData: any): void {
        if (this.isCacheable) {
            BrowserStorage.setItem(cacheKey, JSON.stringify(cacheData), 'sessionStorage');
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private loadConfigFromCache(cacheKey: string): any {
        if (!this.isCacheable) {
            return false;
        }

        const cachedConfig = sessionStorage.getItem(cacheKey);
        // const cachedConfig = BrowserStorage.getItem(cacheKey, 'sessionStorage');  // eventually
        if (cachedConfig) {
            return JSON.parse(cachedConfig);
        }

        return false;
    }

    public loadConfigs = async (businessUnitId: string, refresh: boolean = false) => {
        let configs;
        const cacheKey = `buid-config-${businessUnitId}`;
        const cachedConfig = this.loadConfigFromCache(cacheKey);

        if (cachedConfig && !refresh) {
            configs = cachedConfig;
        } else {
            configs = await this.configMangerApiSdk.getConfigByBusinessUnitId(businessUnitId);
            this.storeInCache(cacheKey, configs);
        }

        this.setBuidConfig(configs);
    };

    public setEcommConfig = (config: Config) => {
        this.ecommConfig = config;
        this.ecommConfig.setMapping(this.configMapping);
    };

    public setMapping(mapping: IConfigMapping) {
        this.configMapping = mapping;
        this.ecommConfig.setMapping(this.configMapping);
        this.buidConfig.setMapping(this.configMapping);
    }

    public getConfigs = async (payload: string[]) => {
        return payload.reduce((acc, configKey) => {
            const key = configMap[configKey] || configKey;
            let configValue: IConfig | string = '';

            if (key === 'ecommConfig') {
                configValue = this.ecommConfig.config;
            } else {
                configValue = this.buidConfig.getConfigByName(key);
                if (configValue === '') {
                    configValue = this.ecommConfig.getConfigByName(key);
                }
            }

            if (configValue === '') {
                const error = new Error(`Config key ${configKey} not found`);
                this.logger.warning({
                    message: error.message,
                    triggerType: TriggerType.config,
                    payload: {
                        error
                    }
                });
            }

            return { ...acc, [configKey]: configValue };
        }, {});
    };

    private setBuidConfig = (configs: IConfig) => {
        this.buidConfig = Config.fromJSON(configs);
        this.buidConfig.setMapping(this.configMapping);
    };
}
