import { Container } from 'hypo-container';
import { root } from '@estee/elc-universal-utils';
import { ConfigMangerApiSdk } from '../api/ConfigMangerApiSdk';
import { ServiceBusController } from './ServiceBusController';
import { ConfigRepository } from '../internal/repositories/ConfigRepository';
import { ConfigManagerService } from './ConfigManagerService';

export const diContainer = new Container();
export const serviceNames = {
    configMangerApiSdk: 'CONFIG_MANGER_API_SDK',
    httpTransport: 'HTTP_TRANSPORT',
    serviceBusController: 'SERVICE_BUS_CONTROLLER',
    configRepository: 'CONFIG_REPOSITORY',
    configManagerService: 'CONFIG_MANAGER_SERVICE'
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setConfig = (configJSON: any) => {
    diContainer.config = configJSON;
};

diContainer
    .register(serviceNames.httpTransport, (c) => {
        return root.HttpTransport;
    })
    .register(serviceNames.configMangerApiSdk, (c) => {
        return new ConfigMangerApiSdk({ httpTransport: c.get(serviceNames.httpTransport) });
    })
    .register(serviceNames.configRepository, (c) => {
        return new ConfigRepository();
    })
    .register(
        serviceNames.serviceBusController,
        (c) => new ServiceBusController(c.get(serviceNames.configRepository))
    )
    .register(
        serviceNames.configManagerService,
        (c) =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            new ConfigManagerService((config: any) => {
                setConfig(config);

                return c.get(serviceNames.serviceBusController);
            })
    );
