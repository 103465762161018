import { ConfigRepository } from '../repositories/ConfigRepository';
import { Config } from '../domain/entities/Config';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { ConfigMappings } from '../domain/config-mappings';

export class ServiceStartedUseCase {
    private configRepository: ConfigRepository;
    private businessUnitId: string;
    private configs: Record<string, unknown>;
    constructor(businessUnitId: string, configs: Record<string, unknown>) {
        this.configRepository = diContainer.get(serviceNames.configRepository);
        this.businessUnitId = businessUnitId;
        const isCoreSite = this.businessUnitId.indexOf('corev1') !== -1;
        const newConfigs = { ...configs, isCoreSite };
        this.configs = newConfigs;
    }

    public execute = async () => {
        const ecommConfig = Config.fromJSON(this.configs);

        // load buid configs data
        await this.configRepository.loadConfigs(this.businessUnitId);
        // set ecomm config data
        this.configRepository.setEcommConfig(ecommConfig);
        // set mapping
        this.configRepository.setMapping(ConfigMappings);
    };
}
