import { IConfigResolver } from './IConfigResolver';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const findVal = (object: any, key: string): any => {
    let value;
    const objKeys = Object.keys(object);
    for (const objKey of objKeys) {
        if (objKey === key) {
            value = object[objKey];
            break;
        }

        if (object[objKey] && typeof object[objKey] === 'object') {
            value = findVal(object[objKey], key);

            if (value !== undefined) {
                break;
            }
        }
    }

    return value;
};

export const DeepSearchResolver: IConfigResolver = (name, ctx) => {
    return findVal(ctx.config, name);
};
