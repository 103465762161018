import { ConfigRepository } from '../internal/repositories/ConfigRepository';
import { queryProvider, serviceQueries } from '@estee/elc-service-bus';

export class ServiceBusController {
    private configRepository: ConfigRepository;
    constructor(configRepository: ConfigRepository) {
        this.configRepository = configRepository;
    }
    @queryProvider(serviceQueries.GET_CONFIG)
    public loadConfigs = async (payload: []) => {
        return this.configRepository.getConfigs(payload);
    };
}
