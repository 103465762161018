import { Checkout } from './Checkout';
import { Compliance } from './Compliance';
import { Account } from './Account';
import { General } from './General';

// eslint-disable-next-line no-restricted-syntax
export const ConfigMappings = {
    ...General,
    ...Account,
    ...Checkout,
    ...Compliance
};
