import { Api, IConfig } from '@estee/elc-service';

const { name, version } = __serviceInfo__;
export interface IConfigStoreData {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

export class ConfigMangerApiSdk extends Api {
    private apiUrl: string;

    constructor(configStore: IConfig) {
        super(configStore, name, version);
        this.apiUrl = 'stardust-buid-configurations-v0';
    }

    public async getConfigByBusinessUnitId(businessUnitId: string): Promise<IConfigStoreData> {
        const url = `/${this.apiUrl}/configurations/${businessUnitId}`;
        const response: IConfigStoreData = await this.get(url);

        return response.data;
    }
}
