import { Service, ServiceInitializer } from '@estee/elc-service';
import { ServiceStartedUseCase } from '../internal/use-cases/ServiceStartedUseCase';
export interface IConfig {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

const { name, version } = __serviceInfo__;

export class ConfigManagerService extends Service {
    constructor(serviceInitializer: ServiceInitializer) {
        super(name, version, serviceInitializer);
        this.start = this.start.bind(this);
    }

    public async start(props: Record<string, unknown>) {
        void super.start(props);
        try {
            await this.setupRepository(props);
        } catch (err) {
            console.error(err);
        }
    }
    private setupRepository = async (props: IConfig) => {
        const businessUnitId = props.businessUnitId;
        const setupRepositoryUseCase = new ServiceStartedUseCase(businessUnitId, props);
        await setupRepositoryUseCase.execute();
    };
}
