import { IConfigMapping } from './IConfigMapping';

export const Checkout: IConfigMapping = {
    checkout_show_terms_and_conditions: 'checkout.termsAndConditions',

    checkout_billing_address_default_format: 'checkout.billing.defaultFormat',
    checkout_billing_address_formats: 'checkout.billing.permittedAddressFormats',

    checkout_allow_guest_user: 'checkout.validation.allowed_guest_user_checkout',

    payment_sources: 'payment.payment_sources'
};
